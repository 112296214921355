<template>
  <div>
    <span style="font-size: 12px">概述：</span>
    <div class="coalblock" v-for="item in options" :key="item.title">
      <span style="font-size: 12px; color: #9196a1">{{ item.title }}</span>
      <!-- <br /> -->
      <span style="font-size: 24px;margin-left: 10px;">{{ item.num }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "overViewOfCoal",
  props: ['tableData', 'total'],
  watch: {
    type (newVal) {
      console.log(this.total)
    }
  },
  data() {
    return {
      options: [{
        title: "系统总数",
        num: 0
      }, {
        title: "子系统总数",
        num: 0
      }]
    }
  },
  mounted() {
    console.log(this.tableData)
    console.log(this.total)
    this.options[0].num = this.total + "个";
    let temp = 0;
    for (let i = 0; i < this.tableData.length; i++) {
      temp = temp + this.tableData[i].subSystemCount
      console.log(this.tableData[i].subSystemCount)
    }
    this.options[1].num = temp + "个";
  }
}
</script>

<style scoped>

</style>
