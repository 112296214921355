<template>
  <div>
    <div>
      <el-breadcrumb
          separator-class="el-icon-arrow-right"
          v-if="this.$router.currentRoute.path != '/home'"
      >
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>元数据管理</el-breadcrumb-item>
        <el-breadcrumb-item>{{crumbA}}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <!--    <span style="font-size: 12px">项目系统概述：</span>-->
    <!--    <div class="coalblock" v-for="item in options" :key="item.title">-->
    <!--      <span style="font-size: 12px; color: #9196a1">{{ item.title }}</span>-->
    <!--      <br />-->
    <!--      <span style="font-size: 24px">{{ item.num }}</span>-->
    <!--    </div>-->

    <!--    <coal-over-view></coal-over-view>-->

    <over-view-of-coal :table-data="tableData" :total="total" v-if="overViewFlag"></over-view-of-coal>

    <el-form :inline="true">
      <el-form-item>
        <!--        v-if="setting.backups"-->
        <!--        v-if="setting.batchremove"-->
        <el-button v-if="isAuth('system:add')" type="primary" @click="addSystem" v-show="setting.backups">增加系统</el-button>
        <el-button v-if="isAuth('system:delete')" type="danger" @click="deleteSystem()" v-show="setting.batchremove">批量删除系统</el-button>
      </el-form-item>

      <el-form-item>

        <span style="font-size: 16px">搜索：</span>
        <el-select
            v-model="currentCoalId"
            placeholder="请选择"
            @change="handleSearch"
            clearable
            style="width: 400px"
        >
          <el-option
              v-for="item in coalOptions"
              :key="item.id"
              :label="item.coal"
              :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>

    <el-table
        :data="tableData"
        style="min-width: 100%"
        border
        v-loading="dataListLoading"

        @selection-change="selectionChangeHandle"
    >
      <el-table-column
          type="selection"
          header-align="center"
          align="center"
          min-width="10%"
      >
      </el-table-column>
      <el-table-column
          label="序号"
          type="index"
          min-width="5%"
          align="center">
        <template slot-scope="scope">
          <span>{{(page - 1) * size + scope.$index + 1}}</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="id"
          label="系统id"
          header-align="center"
          align="center"
          min-width="5%"
          v-if="false"
      >
      </el-table-column>
      <el-table-column
          prop="name"
          label="系统名称"
          header-align="center"
          align="center"
          min-width="10%"
      >
      </el-table-column>
      <el-table-column
          prop="subSystemCount"
          label="子系统个数"
          header-align="center"
          align="center"
          min-width="10%"
      >
      </el-table-column>
      <!--      <el-table-column-->
      <!--          prop="datasize"-->
      <!--          label="数据量"-->
      <!--          header-align="center"-->
      <!--          align="center"-->
      <!--          min-width="10%"-->
      <!--      >-->
      <!--      </el-table-column>-->
      <el-table-column
          prop="createdate"
          label="创建时间"
          header-align="center"
          align="center"
          min-width="10%"
      >
      </el-table-column>
      <el-table-column
          label="操作"
          header-align="center"
          align="center"
          min-width="10%"
      >
        <template slot-scope="scope">
          <!--          <el-button-->
          <!--              type="text"-->
          <!--              size="small"-->
          <!--              @click="handleCheck(scope.row)"-->
          <!--              :disabled="scope.row.subSystemCount == 0?true:false"-->
          <!--          >查看</el-button>-->
          <el-button
              type="text"
              size="small"
              @click="handleCheck(scope.row)"
          >查看</el-button>
          <el-button
              v-if="isAuth('system:update')"
              type="text"
              size="small"
              @click="updateHandle(scope.row)"
          >修改</el-button>
          <el-button
              v-if="isAuth('system:delete')"
              type="text"
              size="small"
              style="color: red"
              @click="deleteSystem(scope.row.id)"
          >删除</el-button
          >
        </template>
      </el-table-column>
      <el-table-column
          prop="remark"
          label="备注"
          header-align="center"
          align="center"
          min-width="20%"
      >
      </el-table-column>
    </el-table>

    <!-- 分页区域 -->
    <div style="display: flex; justify-content: flex-end">
      <el-pagination
          background
          @current-change="currentChange"
          @size-change="sizeChange"
          :current-page="page"
          :page-size="size"
          layout="sizes, prev, pager, next, jumper, ->, total, slot"
          :total="total"
      >
      </el-pagination>
    </div>


    <add-system
        v-if="addSystemDialog"
        ref="addSystem"
        @update="update"
    ></add-system>

    <update-system
        v-if="updateVisible"
        ref="updateSystem"
        @refreshDataList="initDataWithGlobalVar"
    ></update-system>
  </div>
</template>

<script>
import addSystem from "./addSystem";
import coalOverView from "./coalOverView";
import updateSystem from "./updateSystem";
import OverViewOfCoal from "./overViewOfCoal";

export default {
  name: "systemManage",
  data() {
    return {
      name:'项目系统',
      dataListLoading: false,
      dataListSelections: [],
      addSystemDialog: false,
      // options: [
      //   // {
      //   //   title: "总数据量",
      //   //   num: "1.2T",
      //   // },
      //   {
      //     title: "系统个数",
      //     num: "8个",
      //   },
      //   {
      //     title: "子系统系统个数",
      //     num: "12个",
      //   },
      //   {
      //     title: "设备个数",
      //     num: "125",
      //   },
      // ],
      coalOptions: [],
      tableData: [],
      coalIdAsCondition: "",
      page: 1, //当前第几页
      size: 10, //当前每页页数
      total: 0, //全部数据量
      setting: {
        backups: "",
        batchremove: "",
      },
      currOperator: '',
      currOperatorName: '',

      updateVisible: false,
      updateObject: {},
      overViewFlag: false,

      currentCoalId: '',
      currentCoal: '',
      crumbA: '',
      sizeFromSet: 10
    };
  },
  mounted() {
    //获取session中的user的id和name
    this.currOperator = JSON.parse(
        window.sessionStorage.getItem("user")
    ).userId;
    this.currOperatorName = JSON.parse(
        window.sessionStorage.getItem("user")
    ).username;
    //根据名字查询系统设置中的结果
    this.getRequest(
        "/systemSetting/getSettingInfoByName/?name=" + this.name
    ).then((resp) => {
      if (resp.data) {
        console.log(this.name);
        console.log(resp.data);
        this.setting.backups = resp.data.backups;
        this.setting.batchremove = resp.data.batchremove;
        this.sizeFromSet = resp.data.numberperpage;
        this.size = this.sizeFromSet
      }
    });

    if(this.global.coal_id === 0 && this.global.coal_name === '') {
      this.initData_()
    } else {
      this.initDataWithGlobalVar()
    }
    // this.initData();
  },
  components: {
    OverViewOfCoal,
    addSystem,
    coalOverView,
    updateSystem
  },
  methods: {
    currentChange(currentPage) {
      this.page = currentPage;
      this.initDataWithGlobalVar()
      // this.getAllSystemInfoByCoalId();
    },
    sizeChange(currentSize) {
      this.size = currentSize;
      this.initDataWithGlobalVar()
      // this.getAllSystemInfoByCoalId();
    },
    resetSizePage() {
      this.size = this.sizeFromSet
      this.page = 1
    },
    initData_(){
      this.dataListLoading = true;
      this.getRequest("/dataList/getAllCoal/").then((resp) => {
        if (resp.data) {
          this.coalOptions = resp.data;
          if (this.coalOptions.length !== 0) {
            this.currentCoalId = this.coalOptions[0].id
            this.currentCoal = this.coalOptions[0].coal

            // 处理全局变量
            this.$changeGlobalVar(this.global.MODE_COAL, this.currentCoalId, this.currentCoal)

            // 处理面包屑
            this.crumbA = this.global.coal_name
          }
        }
      }).then(() => {
        this.getRequest(
            "/coalSystemManage/getSystemInfoByCoalId/?coalId=" +
            this.currentCoalId +
            "&page=" +
            this.page +
            "&size=" +
            this.size
        ).then((resp) => {
          if (resp.data) {
            this.tableData = resp.data.data;
            this.total = resp.data.total;
            this.overViewFlag = true;
          }
          this.dataListLoading = false
        });
      });
    },
    initDataWithGlobalVar() {
      this.dataListLoading = true
      this.getRequest("/dataList/getAllCoal/").then((resp) => {
        if (resp.data) {
          this.coalOptions = resp.data;
          this.currentCoalId = this.global.coal_id
          this.currentCoal = this.global.coal_name
          // 处理面包屑
          this.crumbA = this.global.coal_name
        }
      }).then(() => {
        this.getRequest(
            "/coalSystemManage/getSystemInfoByCoalId/?coalId=" +
            this.currentCoalId +
            "&page=" +
            this.page +
            "&size=" +
            this.size
        ).then((resp) => {
          if (resp.data) {
            this.tableData = resp.data.data;
            this.total = resp.data.total;
            this.overViewFlag = true;
          }
          this.dataListLoading = false
        })
      })

    },
    handleSearch() {
      this.dataListLoading = true
      this.resetSizePage()
      if (this.currentCoalId === '') {
        this.currentCoal = this.global.coal_name
        this.getRequest(
            "/coalSystemManage/getSystemInfoByCoalId/?coalId=" +
            this.global.coal_id +
            "&page=" +
            this.page +
            "&size=" +
            this.size
        ).then((resp) => {
          if (resp.data) {
            this.tableData = resp.data.data;
            this.total = resp.data.total;
            this.overViewFlag = true;
          }
          this.dataListLoading = false
        })
      } else {
        let obj = {}
        obj = this.coalOptions.find((item)=>{
          return item.id === this.currentCoalId; // 筛选出匹配数据
        });
        this.currentCoal = obj.coal

        // 处理全局变量
        this.$changeGlobalVar(this.global.MODE_COAL, this.currentCoalId, this.currentCoal)

        // 处理面包屑
        this.crumbA = this.global.coal_name
        console.log(this.crumbA)

        this.getRequest(
            "/coalSystemManage/getSystemInfoByCoalId/?coalId=" +
            this.currentCoalId +
            "&page=" +
            this.page +
            "&size=" +
            this.size
        ).then((resp) => {
          if (resp.data) {
            this.tableData = resp.data.data;
            this.total = resp.data.total;
            this.overViewFlag = true;
          }
          this.dataListLoading = false
        });
      }
    },
    handleCheck(row) {
      this.global.system_id = row.id
      this.global.system_name = row.name
      this.$router.push({
        name: 'coalSystemManage-subSystemManage',
      })
      // this.$router.push({
      //   name: 'coalSystemManage-subSystemManage',
      //   query: {
      //     systemId: row.id
      //   }
      // });
    },

    initData() {
      this.dataListLoading = true
      this.getRequest("/dataList/getAllCoal/").then((resp) => {
        if (resp.data) {
          this.coalOptions = resp.data;
          console.log(this.coalOptions);
        }
      });
      this.getRequest(
          "/coalSystemManage/getCoalSystemInfoByPage/?page=" +
          this.page +
          "&size=" +
          this.size + "&userId=" + this.currOperator
      ).then((resp) => {
        if (resp) {
          this.tableData = resp.data.data;
          this.total = resp.data.total;
          this.overViewFlag = true;
        }
        this.dataListLoading = false
      });
      // this.getRequest("/coalSystemManage/getSystemOverview").then((resp) => {
      //   if (resp.data) {
      //     // this.options[0].num = resp.data.datasize + "G";
      //     this.options[1].num = resp.data.systemCount + "个";
      //     this.options[2].num = resp.data.subSystemCount + "个";
      //     this.options[3].num = resp.data.equipmentCount + "个";
      //   }
      // });
    },
    getAllSystemInfoByCoalId() {
      this.dataListLoading = true
      if (this.coalIdAsCondition != '') {
        this.getRequest(
            "/coalSystemManage/getSystemInfoByCoalId/?coalId=" +
            this.coalIdAsCondition +
            "&page=" +
            this.page +
            "&size=" +
            this.size
        ).then((resp) => {
          if (resp.data) {
            this.tableData = resp.data.data;
            this.total = resp.data.total;
            this.overViewFlag = true;
          }
          this.dataListLoading = false
        });
      } else {
        this.getRequest(
            "/coalSystemManage/getCoalSystemInfoByPage/?page=" +
            this.page +
            "&size=" +
            this.size + "&userId=" + this.currOperator
        ).then((resp) => {
          if (resp) {
            this.tableData = resp.data.data;
            this.total = resp.data.total;
            this.overViewFlag = true;
          }
          this.dataListLoading = false
        });
      }
    },
    addSystem() {
      this.addSystemDialog = true;
      this.$nextTick(() => {
        this.$refs.addSystem.init();
      });
    },
    update(status) {
      this.initDataWithGlobalVar();
    },
    // 复选框
    selectionChangeHandle(val) {
      this.dataListSelections = val;
    },
    // 删除系统
    deleteSystem(id) {
      let ids = "?";
      this.$confirm(
          `确定对系统进行[${id ? "删除" : "批量删除"}]操作?`,
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
      )
          .then(() => {
            if (id) {
              this.deleteSubSystem(id);
              ids = "?ids=" + id;
            } else {
              this.dataListSelections.forEach((item) => {
                // 先删除子系统
                this.deleteSubSystem(item.id);
                ids += "ids=" + item.id + "&";
              });
            }
            this.deleteRequest("/coalSystemManage/deleteCoalSystem/" + ids).then(
                (resp) => {
                  if (resp.data) {
                    // this.handleChange();
                    this.initDataWithGlobalVar()
                  }
                }
            );
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
    },
    // 删除子系统
    async deleteSubSystem(id) {
      let ids = "?";
      let subSystem = await this.getRequest("/subSystemManage/getSubSystemInfoBySystemId?systemId=" + id);
      if (subSystem.length) {
        subSystem.forEach( item => {
          // 删除所有的设备
          this.deleteEquipment(item.id);
          ids += "ids=" + item.id + "&";
        })
        await this.deleteRequest("/subSystemManage/deleteSubSystem/" + ids);
      }
    },
    // 删除设备
    async deleteEquipment(id) {
      let ids = "?";
      let equipment = await this.getRequest(
          "/equipmentManage/getEquipmentInfoBySubSystemId?subSystemId=" + id
      );
      if (equipment.length) {
        equipment.forEach((item) => {
          // 先删除所有的附属设备
          this.deleteSubEquipment(item.id);
          ids += "ids=" + item.id + "&";
        });
        await this.deleteRequest("/equipmentManage/deleteEquipment/" + ids);
      }
    },
    // 删除附属设备
    async deleteSubEquipment(id) {
      let ids = "?";
      let subEquipment = await this.getRequest(
          "/subEquipmentManage/getSubEquipmentInfoByEquipmentId?equipmentId=" + id
      );
      if (subEquipment.length) {
        subEquipment.forEach((item) => {
          // 先删除附属设备的所有数据项
          this.deleteMeasurePoint(item.id);
          ids += "ids=" + item.id + "&";
        });
        await this.deleteRequest(
            "/subEquipmentManage/deleteSubEquipment/" + ids
        );
      }
    },
    // 删除附属设备下的所有数据项
    async deleteMeasurePoint(id) {
      let ids = "?";
      let measurePoint = await this.getRequest(
          "/measurePointManage/getMeasurePointInfoBySubEquipmentId?subEquipmentId=" +
          id
      );
      if (measurePoint.length) {
        measurePoint.forEach((item) => {
          ids += "ids=" + item.id + "&";
        });
        await this.deleteRequest(
            "/measurePointManage/deleteMeasurePoint/" + ids
        );
      }
    },

    updateHandle (row) {
      this.updateVisible = true
      this.$nextTick(() => {
        this.$refs.updateSystem.init(row)
      })
    },
  },
};
</script>

<style scoped>
.coalblock {
  /* width: 115px;
  height: 77px;
  border: solid 1px #dcdfe6;
  padding-top: 20px;
  margin-left: 25px;
  text-align: center;
  display: inline-block; */
  text-align: center;
  display: inline-block;
  padding: 3px 5px;
}
</style>
